<template>
  <div>
    <div>{{ field.Value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 14px;
}
</style>
